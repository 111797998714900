import * as apiUrls from "../api-url";
import { AxiosClient } from "../axios-client";
const axiosClient = new AxiosClient();

export const verify = async (data) => {
  return await axiosClient.post(`${apiUrls.USER_VERIFY}`, data);
};

export const login = async (data) => {
  return await axiosClient.post(`${apiUrls.USER_LOGIN}`, data);
};

export const getPaging = async (data) => {
  try {
    let url = `${apiUrls.USERS_GET_PAGING}?pageIndex=${data.pageIndex}&pageSize=${data.pageSize}`;
    url += data.username ? `&username=${data.username}` : '';
    url += data.fullname ? `&fullname=${data.fullname}` : '';
    return await axiosClient.get(url);
  } catch (error) {
    throw error;
  }
};

export const create = async (data) => {
  return await axiosClient.post(`${apiUrls.USER_CREATE}`, data);
}

export const update = async (data) => {
  return await axiosClient.put(`${apiUrls.USER_UPDATE}/${data._id}`, data);
}

export const remove = async (id) => {
  return await axiosClient.delete(`${apiUrls.USER_DELETE}/${id}`);
}