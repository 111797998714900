import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { TOKEN_NAME, J88_BILLMAKER_USER } from "../../utilities/constants";
import { Overlay, Tooltip } from "react-bootstrap";

export default function Header() {
  const logedInUser = JSON.parse(localStorage.getItem(J88_BILLMAKER_USER));
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const logout = () => {
    localStorage.removeItem(TOKEN_NAME);
    localStorage.removeItem(J88_BILLMAKER_USER);
    window.location.reload();
  }

  return (
    <div className="w-100 d-flex justify-content-between align-items-center py-3 pe-4">
      <marquee className="col-6 text-danger fw-bold">Hệ thống BillMaker độc quyền cho J88</marquee>
      <div className="w-auto text-end">
        {
          logedInUser ? (
            <>
              <div className="cursor-pointer d-flex align-items-center" ref={target} onClick={() => setShow(!show)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                </svg>
                <span className="ms-1">{logedInUser.fullname}</span>
              </div>
              <Overlay target={target.current} show={show} placement="bottom">
                {(props) => (
                  <Tooltip {...props} className="bg-light">
                    <div className="p-2">
                      <div>
                        <Link className="text-white text-decoration-none d-flex align-items-center" to='/'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                          </svg>
                          <span className="ms-1">Thông tin</span>
                        </Link>
                      </div>
                      <div className="mt-2">
                        <Link className="text-white text-decoration-none d-flex align-items-center" onClick={() => { logout() }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-left" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0z" />
                            <path fillRule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708z" />
                          </svg>
                          <span className="ms-1">(Đăng xuất)</span>
                        </Link>
                      </div>
                    </div>
                  </Tooltip>
                )}
              </Overlay>
            </>

          ) : (
            <div>
              <Link to='/login'>Đăng nhập</Link>
            </div>
          )
        }
      </div>
    </div>
  )
}