export const USER_LOGIN = `${process.env.REACT_APP_API_URL}/auth/login`;
export const USER_VERIFY = `${process.env.REACT_APP_API_URL}/auth/verify`;

export const USERS_GET_PAGING = `${process.env.REACT_APP_API_URL}/user/getPaging`;
export const USER_CREATE = `${process.env.REACT_APP_API_URL}/user/create`;
export const USER_UPDATE = `${process.env.REACT_APP_API_URL}/user/update`;
export const USER_DELETE = `${process.env.REACT_APP_API_URL}/user/delete`;

export const ROLES_GET_ALL = `${process.env.REACT_APP_API_URL}/role/getAll`;
export const ROLES_GET_PAGING = `${process.env.REACT_APP_API_URL}/role/getPaging`;
export const ROLE_CREATE = `${process.env.REACT_APP_API_URL}/role/create`;
export const ROLE_UPDATE = `${process.env.REACT_APP_API_URL}/role/update`;
export const ROLE_DELETE = `${process.env.REACT_APP_API_URL}/role/delete`;