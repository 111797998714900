import { NavLink, Outlet } from 'react-router-dom';
import styles from './style.module.css';
import Header from '../../../components/header';
import logo from '../../../assets/images/j88-logo.png';

export default function AdminLayout() {
  return (
    <div className={"d-flex"}>
      <div className="col-2 h-100 px-3 pb-3">
        <div className="text-center">
          <NavLink to='/'>
            <img className="col-3 py-3" src={logo} alt="logo" />
          </NavLink>
        </div>
        <div className='mt-4'>
          <NavLink to='admin/user-management' style={{ color: '#000' }} className={({isActive}) => (isActive ? "text-decoration-none fw-bold active" : "text-decoration-none fw-bold")}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-person-gear" viewBox="0 0 16 16">
              <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m.256 7a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382zM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0" />
            </svg>
            <span className='ms-2'>Quản lý tài khoản</span>
          </NavLink>
        </div>
        <div className='mt-4'>
          <NavLink to='admin/role-management' style={{ color: '#000' }} className={({isActive}) => (isActive ? "text-decoration-none fw-bold active" : "text-decoration-none fw-bold")} >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-lock" viewBox="0 0 16 16">
              <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m0 5.996V14H3s-1 0-1-1 1-4 6-4q.845.002 1.544.107a4.5 4.5 0 0 0-.803.918A11 11 0 0 0 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664zM9 13a1 1 0 0 1 1-1v-1a2 2 0 1 1 4 0v1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1zm3-3a1 1 0 0 0-1 1v1h2v-1a1 1 0 0 0-1-1" />
            </svg>
            <span className='ms-2'>Quản lý quyền hệ thống</span>
          </NavLink>
        </div>
      </div>
      <div className="col-10 h-100">
        <Header />
        <div className={"p-4 " + styles.rightSide} style={{minHeight: 'calc(100vh - 90px)'}}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}