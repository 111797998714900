import { Routes, Route } from "react-router-dom";
import React, { lazy, Suspense } from "react";
import AuthRoute from "./auth_route";
import AdminRoute from "./admin_route";
import Loading from "../components/loading";
import UserLayout from "../components/layouts/user_layout";
import AdminLayout from "../components/layouts/admin_layout";

const Dashboard = lazy(() => import("../pages/dashboard"));
const Transfers = lazy(() => import("../pages/transfers"));
const VietcombankTransfer = lazy(() => import("../pages/transfers/vietcombank"));
const TechcombankTransfer = lazy(() => import("../pages/transfers/techcombank"));
const BIDVTransfer = lazy(() => import("../pages/transfers/bidv"));
const SacombankTransfer = lazy(() => import("../pages/transfers/sacombank"));
const VietinbankTransfer = lazy(() => import("../pages/transfers/vietinbank"));
const MSBTransfer = lazy(() => import("../pages/transfers/msb"));
const VpbankTransfer = lazy(() => import("../pages/transfers/vpbank"));
const MomoTransfer = lazy(() => import("../pages/transfers/momo"));
const MBBankTransfer = lazy(() => import("../pages/transfers/mbbank"));
const AgribankTransfer = lazy(() => import("../pages/transfers/agribank"));
const TPBankTransfer = lazy(() => import("../pages/transfers/tpbank"));
const ACBTransfer = lazy(() => import("../pages/transfers/acb"));
const OCBTransfer = lazy(() => import("../pages/transfers/ocb"));
const DongABankTransfer = lazy(() => import("../pages/transfers/dongabank"));
const PVComBankTransfer = lazy(() => import("../pages/transfers/pvcombank"));
const NamABankTransfer = lazy(() => import("../pages/transfers/namabank"));
const MBBankPriorityTransfer = lazy(() => import("../pages/transfers/mbbank_priority"));
const CakeTransfer = lazy(() => import("../pages/transfers/cake"));
const SeabankTransfer = lazy(() => import("../pages/transfers/seabank"));
const LPBankTransfer = lazy(() => import("../pages/transfers/lpbank"));
const BVBankTransfer = lazy(() => import("../pages/transfers/bvbank"));
const ViettelMoneyTransfer = lazy(() => import("../pages/transfers/viettel_money"));
const VIBTransfer = lazy(() => import("../pages/transfers/vib"));
const SHBTransfer = lazy(() => import("../pages/transfers/shb"));

const Transactions = lazy(() => import("../pages/transactions"));
const VietcombankTransaction = lazy(() => import("../pages/transactions/vietcombank"));
const TechcombankTransaction = lazy(() => import("../pages/transactions/techcombank"));
const MBBankTransaction = lazy(() => import("../pages/transactions/mbbank"));
const TPBankTransaction = lazy(() => import("../pages/transactions/tpbank"));
const MomoTransaction = lazy(() => import("../pages/transactions/momo"));

const Balances = lazy(() => import("../pages/balances"));
const BIDVBalance = lazy(() => import("../pages/balances/bidv"));
const AgribankBalance = lazy(() => import("../pages/balances/agribank"));
const VietcombankBalance = lazy(() => import("../pages/balances/vietcombank"));
const TPBankBalance = lazy(() => import("../pages/balances/tpbank"));
const VPBankBalance = lazy(() => import("../pages/balances/vpbank"));
const VietinbankBalance = lazy(() => import("../pages/balances/vietinbank"));
const MBBankBalance = lazy(() => import("../pages/balances/mbbank"));

const SMS = lazy(() => import("../pages/sms"));
const TPBankSMS = lazy(() => import("../pages/sms/tpbank"));
const ACBSMS = lazy(() => import("../pages/sms/acb"));
const AgribankSMS = lazy(() => import("../pages/sms/agribank"));
const BIDVSMS = lazy(() => import("../pages/sms/bidv"));
const DongABankSMS = lazy(() => import("../pages/sms/dongabank"));
const VietcombankSMS = lazy(() => import("../pages/sms/vietcombank"));
const VietinBankSMS = lazy(() => import("../pages/sms/vietinbank"));
const MBBankSMS = lazy(() => import("../pages/sms/mbbank"));
const OCBSMS = lazy(() => import("../pages/sms/ocb"));

const WithdrawalRequest = lazy(() => import("../pages/withdrawal_request"));
const TransactionHistory = lazy(() => import("../pages/transaction_history"));
const HomeScreen = lazy(() => import("../pages/home_screen"));
const GameScreen = lazy(() => import("../pages/game_screen"));
const WithdrawalHistory = lazy(() => import("../pages/withdrawal_history"));
const RefundHistory = lazy(() => import("../pages/refund_history"));
const VipLevel = lazy(() => import("../pages/vip_level"));

const Invoice = lazy(() => import("../pages/invoice"));
const Invoice_1 = lazy(() => import("../pages/invoice/invoice_1"));
const Invoice_2 = lazy(() => import("../pages/invoice/invoice_2"));
const Invoice_3 = lazy(() => import("../pages/invoice/invoice_3"));
const Invoice_4 = lazy(() => import("../pages/invoice/invoice_4"));
const Invoice_5 = lazy(() => import("../pages/invoice/invoice_5"));
const Invoice_6 = lazy(() => import("../pages/invoice/invoice_6"));
const Invoice_7 = lazy(() => import("../pages/invoice/invoice_7"));
const Invoice_8 = lazy(() => import("../pages/invoice/invoice_8"));

const Admin = lazy(() => import("../pages/admin"));
const UserManagement = lazy(() => import("../pages/admin/user_management"));
const RoleManagement = lazy(() => import("../pages/admin/role_management"));
const Login = lazy(() => import("../pages/login"));
const ErrorPage = lazy(() => import("../pages/error"));

export default function Router() {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route element={<UserLayout />}>
          <Route path="/">
            <Route index={true} element={
              <AuthRoute>
                <Dashboard />
              </AuthRoute>
            } />
          </Route>

          {/* Transfers */}
          <Route path="transfers">
            <Route index={true} element={
              <AuthRoute>
                <Transfers />
              </AuthRoute>
            } />
            <Route index={false} path="vietcombank" element={
              <AuthRoute>
                <VietcombankTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="techcombank" element={
              <AuthRoute>
                <TechcombankTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="bidv" element={
              <AuthRoute>
                <BIDVTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="sacombank" element={
              <AuthRoute>
                <SacombankTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="vietinbank" element={
              <AuthRoute>
                <VietinbankTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="msb" element={
              <AuthRoute>
                <MSBTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="vpbank" element={
              <AuthRoute>
                <VpbankTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="momo" element={
              <AuthRoute>
                <MomoTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="mbbank" element={
              <AuthRoute>
                <MBBankTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="agribank" element={
              <AuthRoute>
                <AgribankTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="tpbank" element={
              <AuthRoute>
                <TPBankTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="acb" element={
              <AuthRoute>
                <ACBTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="ocb" element={
              <AuthRoute>
                <OCBTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="dongabank" element={
              <AuthRoute>
                <DongABankTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="pvcombank" element={
              <AuthRoute>
                <PVComBankTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="namabank" element={
              <AuthRoute>
                <NamABankTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="mbbank-priority" element={
              <AuthRoute>
                <MBBankPriorityTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="cake" element={
              <AuthRoute>
                <CakeTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="seabank" element={
              <AuthRoute>
                <SeabankTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="lpbank" element={
              <AuthRoute>
                <LPBankTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="bvbank" element={
              <AuthRoute>
                <BVBankTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="viettel-money" element={
              <AuthRoute>
                <ViettelMoneyTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="vib" element={
              <AuthRoute>
                <VIBTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="shb" element={
              <AuthRoute>
                <SHBTransfer />
              </AuthRoute>
            } />
          </Route>

          {/* Transactions */}
          <Route path="transactions">
            <Route index={true} element={
              <AuthRoute>
                <Transactions />
              </AuthRoute>
            } />
            <Route index={true} path="vietcombank" element={
              <AuthRoute>
                <VietcombankTransaction />
              </AuthRoute>
            } />
            <Route index={true} path="techcombank" element={
              <AuthRoute>
                <TechcombankTransaction />
              </AuthRoute>
            } />
            <Route index={true} path="mbbank" element={
              <AuthRoute>
                <MBBankTransaction />
              </AuthRoute>
            } />
            <Route index={true} path="tpbank" element={
              <AuthRoute>
                <TPBankTransaction />
              </AuthRoute>
            } />
            <Route index={true} path="momo" element={
              <AuthRoute>
                <MomoTransaction />
              </AuthRoute>
            } />
          </Route>

          {/* Balances */}
          <Route path="balances">
            <Route index={true} element={
              <AuthRoute>
                <Balances />
              </AuthRoute>
            } />
            <Route index={false} path="bidv" element={
              <AuthRoute>
                <BIDVBalance />
              </AuthRoute>
            } />
            <Route index={false} path="agribank" element={
              <AuthRoute>
                <AgribankBalance />
              </AuthRoute>
            } />
            <Route index={false} path="vietcombank" element={
              <AuthRoute>
                <VietcombankBalance />
              </AuthRoute>
            } />
            <Route index={false} path="tpbank" element={
              <AuthRoute>
                <TPBankBalance />
              </AuthRoute>
            } />
            <Route index={false} path="vpbank" element={
              <AuthRoute>
                <VPBankBalance />
              </AuthRoute>
            } />
            <Route index={false} path="vietinbank" element={
              <AuthRoute>
                <VietinbankBalance />
              </AuthRoute>
            } />
            <Route index={false} path="mbbank" element={
              <AuthRoute>
                <MBBankBalance />
              </AuthRoute>
            } />
          </Route>

          {/* SMS */}
          <Route path="sms">
            <Route index={true} element={
              <AuthRoute>
                <SMS />
              </AuthRoute>
            } />
            <Route index={false} path="tpbank" element={
              <AuthRoute>
                <TPBankSMS />
              </AuthRoute>
            } />
            <Route index={false} path="acb" element={
              <AuthRoute>
                <ACBSMS />
              </AuthRoute>
            } />
            <Route index={false} path="agribank" element={
              <AuthRoute>
                <AgribankSMS />
              </AuthRoute>
            } />
            <Route index={false} path="bidv" element={
              <AuthRoute>
                <BIDVSMS />
              </AuthRoute>
            } />
            <Route index={false} path="dongabank" element={
              <AuthRoute>
                <DongABankSMS />
              </AuthRoute>
            } />
            <Route index={false} path="vietcombank" element={
              <AuthRoute>
                <VietcombankSMS />
              </AuthRoute>
            } />
            <Route index={false} path="vietinbank" element={
              <AuthRoute>
                <VietinBankSMS />
              </AuthRoute>
            } />
            <Route index={false} path="mbbank" element={
              <AuthRoute>
                <MBBankSMS />
              </AuthRoute>
            } />
            <Route index={false} path="ocb" element={
              <AuthRoute>
                <OCBSMS />
              </AuthRoute>
            } />
          </Route>

          {/* Withdrawal Request */}
          <Route path="withdrawal-request">
            <Route index={true} element={
              <AuthRoute>
                <WithdrawalRequest />
              </AuthRoute>
            } />
          </Route>

          {/* Transaction History */}
          <Route path="transaction-history">
            <Route index={true} element={
              <AuthRoute>
                <TransactionHistory />
              </AuthRoute>
            } />
          </Route>

          {/* Home Screen */}
          <Route path="home-screen">
            <Route index={true} element={
              <AuthRoute>
                <HomeScreen />
              </AuthRoute>
            } />
          </Route>

          {/* Game Screen */}
          <Route path="game-screen">
            <Route index={true} element={
              <AuthRoute>
                <GameScreen />
              </AuthRoute>
            } />
          </Route>

          {/* Withdrawal History */}
          <Route path="withdrawal-history">
            <Route index={true} element={
              <AuthRoute>
                <WithdrawalHistory />
              </AuthRoute>
            } />
          </Route>

          {/* Refund History */}
          <Route path="refund-history">
            <Route index={true} element={
              <AuthRoute>
                <RefundHistory />
              </AuthRoute>
            } />
          </Route>

          {/* VIP Level */}
          <Route path="vip-level">
            <Route index={true} element={
              <AuthRoute>
                <VipLevel />
              </AuthRoute>
            } />
          </Route>

          {/* Invoice */}
          <Route path="invoice">
            <Route index={true} element={
              <AuthRoute>
                <Invoice />
              </AuthRoute>
            } />
            <Route index={false} path="invoice-1" element={
              <AuthRoute>
                <Invoice_1 />
              </AuthRoute>
            } />
            <Route index={false} path="invoice-2" element={
              <AuthRoute>
                <Invoice_2 />
              </AuthRoute>
            } />
            <Route index={false} path="invoice-3" element={
              <AuthRoute>
                <Invoice_3 />
              </AuthRoute>
            } />
            <Route index={false} path="invoice-4" element={
              <AuthRoute>
                <Invoice_4 />
              </AuthRoute>
            } />
            <Route index={false} path="invoice-5" element={
              <AuthRoute>
                <Invoice_5 />
              </AuthRoute>
            } />
            <Route index={false} path="invoice-6" element={
              <AuthRoute>
                <Invoice_6 />
              </AuthRoute>
            } />
            <Route index={false} path="invoice-7" element={
              <AuthRoute>
                <Invoice_7 />
              </AuthRoute>
            } />
            <Route index={false} path="invoice-8" element={
              <AuthRoute>
                <Invoice_8 />
              </AuthRoute>
            } />
          </Route>
        </Route>

        {/* ADMIN ROUTES */}
        <Route element={<AdminLayout />}>
          <Route path="admin">
            <Route index={true} element={
              <AdminRoute>
                <Admin />
              </AdminRoute>
            } />
            <Route path="user-management" element={
              <AdminRoute>
                <UserManagement />
              </AdminRoute>
            } />
            <Route path="role-management" element={
              <AdminRoute>
                <RoleManagement />
              </AdminRoute>
            } />
          </Route>
        </Route>

        <Route path="login" element={<Login />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Suspense>
  );
}