import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { ACCESS_ADMIN_ACTION, TOKEN_NAME } from '../utilities/constants';
import { setAuthorization } from '../services/axios-client';
import { verify } from '../services/user';
import { toast } from 'react-toastify';

const AdminRoute = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem(TOKEN_NAME);
    if (!token) {
      return navigate('/login', { replace: true });
    }
    setAuthorization(token);
    verifyLogedInUser();
  }, [])

  const verifyLogedInUser = async () => {
    try {
      const response = await verify();
      if (!response.data?.role.actions.includes(ACCESS_ADMIN_ACTION)) {
        toast.error('Không có quyền truy cập trang quản lý!');
        return navigate('/login', { replace: true });
      }
    } catch (error) {
      toast.error('Không có quyền truy cập trang quản lý!');
      return navigate('/login', { replace: true });
    }
  }
  return children;
};

export default AdminRoute;